import React, { useState, useEffect, useCallback } from "react";
import styles from "./payment.module.css";
import { message, Tooltip } from "antd";
import buttonIcon from "../../assets/Button.png";
import BannerStyle from "./banner.module.css";
import { publicRequest } from "../../requestMethod";
import { useTranslation } from "react-i18next";
import tickIcon from "../../assets/tickIcon.png";
const Payment = ({ setLocation, formData, companyDetails }) => {
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);

  const [discount, setDiscount] = useState(0);
  const [discountMonths, setDiscountMonths] = useState(0);
  const [voucher, setVoucher] = useState("");
  const [voucherDiscount, setVoucherDiscount] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [legalRepresentativeData, setLegalRepresentativeData] = useState(null);

  const clearVoucher = () => {
    setVoucher("");
    setDiscount(0);
    setDiscountMonths(0);
  };

  const fetchVoucherCode = useCallback(async () => {
    try {
      const response = await publicRequest.get(`/voucher/verify/${voucher}`);
      setVoucherDiscount(response.data.discount);
      console.log("response", response);

      if (response.data.discount === "25% off") {
        setDiscount(selected?.price * 0.75);
      } else if (response.data.discount === "50% off") {
        setDiscount(selected?.price * 0.5);
      } else if (response.data.discount === "75% off") {
        setDiscount(selected?.price * 0.25);
      } else if (response.data.discount === "Buy 1 Get 1 free") {
        setDiscountMonths(selected?.timePeriod + 1);
      } else if (response.data.discount === "1 month free") {
        setDiscountMonths(selected?.timePeriod);
      } else if (response.data.discount === "3 months free") {
        setDiscountMonths(selected?.timePeriod + 3 - 1);
      } else if (response.data.discount === "6 months free") {
        setDiscountMonths(selected?.timePeriod + 6 - 1);
      } else if (response.data.discount === "12 months free") {
        setDiscountMonths(selected?.timePeriod + 12 - 1);
      } else {
        setDiscount(0);
        setDiscountMonths(0);
      }
      message.success("successfully applied discount");
    } catch (error) {
      message.error("Vocher expire");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher]);

  useEffect(() => {
    if (voucher && discount === 0) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(
        setTimeout(() => {
          fetchVoucherCode();
        }, 1000) // Adjust the timeout as needed
      );
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher, discount]);

  const handleClick = async () => {
    console.log("Inside register");
    if (selected) {
      try {
        data.legalDetails = legalRepresentativeData;
        console.log("data", data);
        const response = await publicRequest.post(`/payment`, {
          purchaseType: "SUBSCRIPTION",
          selected,
          discount: voucherDiscount,
          successUrl: new URL("/register?step=3", window.location.href),
          voucher,
        });

        localStorage.setItem("sessionId", response?.data?.sessionId);
        localStorage.setItem(
          "formValue",
          JSON.stringify({
            selected,
          })
        );

        const userData = companyDetails?.businessDetails;

        localStorage.setItem(
          "userData",
          JSON.stringify({
            userData,
          })
        );
        if (response.data.url) {
          window.location.href = response.data.url;
        }
      } catch (err) {
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      }
    } else {
      message.error("Select a plan");
    }
  };

  const handleBack = () => {
    setLocation("form2");
  };

  useEffect(() => {
    try {
      const value = JSON.parse(localStorage.getItem("formValue"));
      const legalRepresentativeValue = JSON.parse(
        localStorage.getItem("legalRepresentativeData")
      );
      console.log("legalRepresentativeValue", legalRepresentativeValue);
      setLegalRepresentativeData(legalRepresentativeValue);
      setSelected(value.selected);
    } catch (error) {}
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await publicRequest.get("package");
        const partnerSubscription = response.data.data.filter(
          (d) => d.userType === "PARTNER"
        );

        setData(partnerSubscription);
        // setSelected(partnerSubscription?.[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner_container}>
        <div className={BannerStyle.paymentBanner}>
          <div className={BannerStyle.paymentBannerTitle}>
            {t("payment.welcome_banner_1")}
          </div>
          <div className={BannerStyle.paymentBannerSubTitle}>
            {t("payment.welcome_banner_2")}
          </div>
          <div className={styles.choose_pkg}>
            <p>{t("payment.choose_package")}</p>
          </div>
        </div>
      </div>

      <div className={styles.main_con}>
        {/* Cards Section */}
        <div className={styles.maincards_div}>
          <div className={styles.cards_div}>
            {data?.map((_data) => (
              <div
                key={_data._id}
                style={{
                  border:
                    selected?._id === _data._id
                      ? "2px solid black"
                      : "2px solid lightgray",
                }}
                className={styles.card1}
              >
                <div className={styles.innerCard}>
                  <div className={styles.mostWatch}>
                    <p
                      style={{
                        fontSize: "21px",
                        fontWeight: "bold",
                      }}
                    >
                      {_data.planName.charAt(0).toUpperCase() +
                        _data.planName.slice(1).toLowerCase()}
                    </p>
                    {_data?.planName === "BUSINESS" && (
                      <p
                        className={styles.mostWatchButton}
                        style={{
                          color: "white",
                          fontSize: "11px",
                        }}
                      >
                        Most Popular
                      </p>
                    )}
                  </div>
                  <div className={styles.voucher_text}>
                    <span className={styles.voucher_prices}>
                      {_data.price || 0}€
                    </span>
                    /month
                  </div>
                  <div className={styles.voucher_point}>
                    <img src={tickIcon} className={styles.tick} alt=""></img>
                    {_data?.planName === "STARTER" && (
                      <p
                        style={{
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Guaranteed place on our map
                      </p>
                    )}
                    {_data?.planName === "BUSINESS" && (
                      <p
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Enhanced Visibility
                      </p>
                    )}
                    {_data?.planName === "ENTERPRISE" && (
                      <p
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        In app advertising{" "}
                      </p>
                    )}
                    <Tooltip
                      title={t(
                        _data?.planName === "STARTER"
                          ? "payment.starter_plan1"
                          : _data?.planName === "BUSINESS"
                          ? "payment.bussiness_plan1"
                          : _data?.planName === "ENTERPRISE"
                          ? "payment.enterprise_plan1"
                          : ""
                      )}
                    >
                      <img
                        src={buttonIcon}
                        className={styles.buttonIcon}
                        alt="Button Icon"
                      />
                    </Tooltip>
                  </div>
                  <div className={styles.voucher_point}>
                    <img src={tickIcon} className={styles.tick} alt=""></img>
                    {_data?.planName === "STARTER" && (
                      <p
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Stamp card advantage
                      </p>
                    )}

                    {_data?.planName === "BUSINESS" && (
                      <p
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Preferred representation
                      </p>
                    )}

                    {_data?.planName === "ENTERPRISE" && (
                      <p
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        In app advertising (1x / Month)
                      </p>
                    )}
                    <Tooltip
                      title={t(
                        _data?.planName === "STARTER"
                          ? "payment.starter_plan2"
                          : _data?.planName === "BUSINESS"
                          ? "payment.bussiness_plan2"
                          : _data?.planName === "ENTERPRISE"
                          ? "payment.enterprise_plan2"
                          : ""
                      )}
                    >
                      <img
                        src={buttonIcon}
                        className={styles.buttonIcon}
                        alt="Button Icon"
                      />
                    </Tooltip>
                  </div>
                </div>
                <button
                  onClick={() => setSelected(_data)}
                  className={styles.bttn}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    border:
                      selected?._id === _data._id
                        ? "2px solid #8DC3DF"
                        : "2px solid black",
                    backgroundColor:
                      selected?._id === _data._id ? "#8DC3DF" : "transparent",
                    fontSize: "12px",
                    color: selected?._id === _data._id ? "white" : "black",
                  }}
                >
                  {selected?._id === _data._id
                    ? t("payment.selected_package")
                    : t("payment.select_package")}
                </button>
               </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.form2_main}>
        <div className={styles.reg_process} style={{ marginBottom: "30px" }}>
          <p className={styles.headingPayment}>Payment Method</p>
          <p className={styles.subHeadingPayment}>
            Select your preferred payment method
          </p>
        </div>
        <div className={styles.grid}>
          <div
            onClick={() => setPaymentMethod("stripe")}
            className={`${styles.klarna} ${
              paymentMethod === "stripe" ? styles.selected : ""
            }`}
            style={{
              display: "flex",
              justifyContent: "space-evenly"
            }}
          >
            <label
              style={{
                fontSize: "16px",
                fontWeight: 700,
              }}
              htmlFor="stripe"
            >
              {t("payment.payment_method_stripe")}
            </label>
            <img
              src="Images/stripe.png"
              className={styles.icon_pic}
              alt="missing"
            />
          </div>
          <div
            onClick={() => setPaymentMethod("paypal")}
            className={`${styles.klarna} ${
              paymentMethod === "paypal" ? styles.selected : ""
            }`}
            style={{
              display: "flex",
              justifyContent: "space-evenly"
            }}
          >
            <label style={{
                fontSize: "16px",
                fontWeight: 700,
              }} htmlFor="paypal">{t("payment.payment_method_paypal")}</label>
            <img
              src="Images/paypal.png"
              className={styles.icon_pic}
              alt="missing"
            />
          </div>
        </div>
        <div className={styles.voucher}>
          <p>Got a voucher?</p>
        </div>
        <div className={styles.enter_voucher}>
          <input
            disabled={discount > 0 || discountMonths > 0}
            type="text"
            placeholder="Enter your voucher here"
            value={voucher}
            onChange={(e) => {
              setVoucher(e.target.value);
            }}
          />
          <div className={styles.apply}>
            <button title="Remove Voucher">
              Apply{" "}
            </button>
          </div>
          {voucher && (
            <button
              className={styles.remove_voucher}
              onClick={clearVoucher}
              title="Remove Voucher"
            >
              &times; {/* Cross button */}
            </button>
          )}
        </div>
        <div className={styles.continue}>
          <button onClick={handleBack}>{t("payment.button_back")}</button>
          <button onClick={handleClick}>
            {/* {t("payment.button_final_step")} */}Continue
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Payment;
